var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"1"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"right":_vm.x === 'right',"timeout":_vm.timeout,"top":_vm.y === 'top'},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarMessage))]),_c('v-row',[_c('loading',{attrs:{"active":_vm.isLoading,"loader":_vm.loader}})],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"xs":"12","sm":"1","md":"1"}},[_c('v-btn',{staticClass:"error mt-2",attrs:{"outlined":"","dark":""},on:{"click":function($event){return _vm.back()}}},[_vm._v("BACK")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"xs":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{
            'items-per-page-options': [10, 20, 30]
          },"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.orderDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(_vm.moment(item.orderDate).format("MMMM DD, YYYY")))]}},{key:"item.viewDetails",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none !important"},attrs:{"to":{
                name: 'Order Detail',
                query: { orderId: item.orderId,partyId: item.partyId},
              }}},[_c('v-btn',{attrs:{"outlined":"","fab":"","small":"","color":"info","size":"25"}},[_c('v-icon',[_vm._v("visibility")])],1)],1)]}},{key:"item.statusId",fn:function(ref){
              var item = ref.item;
return [(item.statusId == 'ORDER_APPROVED')?_c('span',[_vm._v("Approved")]):_vm._e(),(item.statusId == 'ORDER_CREATED')?_c('span',[_vm._v("Created")]):_vm._e(),(item.statusId == 'ORDER_CANCELLED')?_c('span',[_vm._v("Cancel")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }